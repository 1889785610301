// src/App.js

import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Navigation from './components/Navigation'; // Updated Navigation Component
import HomePage from './pages/HomePage';
import OverOnsPage from './pages/OverOnsPage';
import SessionPage from './pages/SessionPage';
import NotFoundPage from './pages/NotFoundPage';
import StoryDisplay from './components/StoryDisplay/StoryDisplay'; // Import StoryDisplay
import Contact from './pages/Contact'; // Import the new Contact component
import { UUIDProvider } from './contexts/UUIDContext';
import ReactGA from 'react-ga4'; // Import ReactGA
import LoginPromptModal from './components/LoginPromptModal';
import VeelGesteldeVragen from './pages/veelGesteldeVragen';
import BetalingGedaan from './pages/BetalingGedaan';
import AanvragenKlassenlink from './pages/AanvragenKlassenlink';
import MijnVerhalenBibliotheek from './pages/MijnVerhalenBibliotheek'; // Import de nieuwe pagina
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0
import TagManager from 'react-gtm-module'; // Import react-gtm-module
import AbonnementenModalProvider from './components/AbonnementenModal'; // Updated import
import { UserStatusProvider } from './contexts/UserStatusContext';
import './index.css'; // or './App.css', if that’s where you put the global styles
import AlgemeneVoorwaardenPage from './pages/AlgemeneVoorwaardenPage'; // Nieuwe import
import PrivacybeleidPage from './pages/PrivacybeleidPage'; // Nieuwe import
 
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || 'G-YE9Z8MFMJD'; // Ensure this is set correctly
const GTM_ID = process.env.REACT_APP_GTM_ID || 'GTM-TR7MX75R'; // Add your GTM ID here

// Initialize GA outside of component to avoid re-initialization
ReactGA.initialize(TRACKING_ID);

const GAListener = () => {
  const location = useLocation();

  useEffect(() => {
    console.log('GAListener: Tracking page view:', location.pathname + location.search);
    // Send pageview with the current path
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return null;
};

// New Component to handle /qrcode redirection and event logging
const QrCodeRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log('QrCodeRedirect: Logging QR code visit event');
    // Log the 'qr_code_visit' event
    ReactGA.event({
      category: 'QR Code',
      action: 'qr_code_visit',
      label: 'QR Code Page Visited',
    });

    console.log('QrCodeRedirect: Redirecting to home page');
    // Redirect to the home page
    navigate('/');
  }, [navigate]);

  return null; // This component doesn't render anything
};

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/over-ons" element={<OverOnsPage />} />
    <Route path="/session/:id" element={<SessionPage />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/story/:uuid" element={<StoryDisplay />} /> {/* New Route */}
    <Route path="/qrcode" element={<QrCodeRedirect />} /> {/* Added QrCodeRedirect Route */}
    <Route path="/veel-gestelde-vragen" element={<VeelGesteldeVragen />} />
    <Route path="/mijn-verhalen-bibliotheek" element={<MijnVerhalenBibliotheek />} /> {/* Nieuwe route */}
    <Route path="/aanvragen-klassenlink" element={<AanvragenKlassenlink />} />
    <Route path="/betaling-gedaan" element={<BetalingGedaan />} />
    {/* Nieuwe routes voor Algemene Voorwaarden en Privacybeleid */}
    <Route path="/algemene-voorwaarden" element={<AlgemeneVoorwaardenPage />} />
    <Route path="/privacybeleid" element={<PrivacybeleidPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

const App = () => {
  const { isAuthenticated, user, isLoading, error } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize Google Tag Manager
    const tagManagerArgs = {
      gtmId: GTM_ID,
      // Optional: additional GTM configuration
      // dataLayer: {
      //   userId: user?.id || '',
      // },
    };

    TagManager.initialize(tagManagerArgs);

    // Initialize GA (if you prefer to handle GA via GTM, you can remove react-ga4)
    // ReactGA.initialize(TRACKING_ID);

  }, [GTM_ID]);

  useEffect(() => {
    if (isLoading) {
      console.log('App: Authentication is loading...');
    } else if (error) {
      console.error('App: Authentication error:', error);
    } else if (isAuthenticated) {
      console.log('App: User is authenticated:', user);
      console.log('App: Current location:', location.pathname + location.search);
      
      // Retrieve the returnTo path from sessionStorage
      const returnTo = sessionStorage.getItem('returnTo');
      if (returnTo && returnTo !== location.pathname + location.search) {
        console.log(`App: Redirecting to stored path: ${returnTo}`);
        navigate(returnTo, { replace: true });
        sessionStorage.removeItem('returnTo');
      }
    } else {
      console.log('App: User is not authenticated');
    }
  }, [isAuthenticated, user, isLoading, error, location, navigate]);

  return (
    <UUIDProvider>
          <UserStatusProvider>
          <AbonnementenModalProvider>
      <GAListener /> {/* GA Listener to track route changes */}
      <Navigation />
      {/* Main Content */}
      <div style={{ paddingBottom: '64px' }}> {/* 64px is the height of AppBar */}
        <AppRoutes />
      </div>
      <LoginPromptModal />
      </AbonnementenModalProvider>
      </UserStatusProvider>
    </UUIDProvider>
  );
};

export default App;
