// src/components/AbonnementenModal.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  Collapse,
  useTheme,
  Chip,
  Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import ReactGA from 'react-ga4';
import { UserStatusContext } from '../contexts/UserStatusContext';

const AbonnementenModalContext = createContext();
export const useAbonnementenModal = () => useContext(AbonnementenModalContext);

export const AbonnementenModalProvider = ({ children }) => {
  const { user, isAuthenticated } = useAuth0();
  const { userCredits } = useContext(UserStatusContext);
  const theme = useTheme();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [surveyVisible, setSurveyVisible] = useState(false);
  const [daysUntilRefill, setDaysUntilRefill] = useState(null);
  const [orgExpanded, setOrgExpanded] = useState(false);

  const reviews = [
    { name: 'Sophie', text: 'Fantastisch! Mijn kinderen vinden de verhalen zo gaaf.' },
    { name: 'Bas', text: 'Eindelijk een oplossing voor leesproblemen, echt effectief!' },
    { name: 'Evelien', text: 'De verhalen zijn ontzettend behulpzaam voor mijn zoon.' },
    { name: 'Tom', text: 'Mijn dochter is super enthousiast, eindelijk leesplezier.' },
    { name: 'Lisa', text: 'Ze zijn meteen geboeid en leren er veel van.' },
    { name: 'Mark', text: 'Geweldige aanpak, lost echt leesproblemen op.' },
    { name: 'Anna', text: 'Zo praktisch en motiverend, mijn kids vinden het fantastisch.' },
    { name: 'Joris', text: 'Het werkt! Mijn kinderen lezen nu veel meer.' },
    { name: 'Nina', text: 'Een echte doorbraak voor onze leesroutine.' },
    { name: 'Daan', text: 'Effectief en leuk, ze zijn helemaal weg van de verhalen.' },
    { name: 'Eva', text: 'De verhalen maken lezen spannend en leerzaam.' },
    { name: 'Koen', text: 'Eindelijk iets wat helpt bij hun leesuitdagingen.' },
    { name: 'Sanne', text: 'Heel behulpzaam en ze vinden het super gaaf.' },
    { name: 'Luuk', text: 'Een oplossing waar ik op wacht, echt effectief.' },
    { name: 'Mila', text: 'Ze zijn er dol op en leren er veel van.' },
    { name: 'Finn', text: 'Het lost echt een probleem op en motiveert ze om te lezen.' },
    { name: 'Roos', text: 'Zo leuk en leerzaam, perfect voor mijn kinderen.' },
    { name: 'Lars', text: 'Een geweldige manier om leesproblemen aan te pakken.' },
    { name: 'Fleur', text: 'Eindelijk plezier in lezen, en dat werkt!' },
    { name: 'Jade', text: 'De verhalen zijn gaaf, motiveren en helpen echt.' },
    { name: 'Sem', text: 'Betrouwbaar, behulpzaam en lost leesproblemen op.' },
    { name: 'Iris', text: 'Mijn kinderen zijn enthousiast en leren snel.' },
    { name: 'Jasper', text: 'Echt een slimme oplossing voor leesuitdagingen.' },
    { name: 'Zoë', text: 'De verhalen maken lezen weer leuk en effectief.' },
    { name: 'Mats', text: 'Mijn zoon vindt het zo gaaf, hij leest nu elke dag!' }
  ];
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const userName = user?.name?.split(' ')[0] || 'lezer';

  useEffect(() => {
    const calcDaysUntilRefill = () => {
      const today = new Date();
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      const diffTime = nextMonth - today;
      setDaysUntilRefill(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
    };
    calcDaysUntilRefill();
    const refillInterval = setInterval(calcDaysUntilRefill, 1000 * 60 * 60 * 24);
    return () => clearInterval(refillInterval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * reviews.length);
      setCurrentReviewIndex(randomIndex);
    }, 5000);
    return () => clearInterval(interval);
  }, [reviews.length]);

  const openAbonnementenModal = () => {
    ReactGA.event({
      category: 'Modal',
      action: 'AboModalOpened',
      label: 'Subscription modal opened',
    });
    setIsModalOpen(true);
  };

  const closeModalWithReason = (reason) => {
    ReactGA.event({
      category: 'Modal',
      action: 'AboModalCloseReason',
      label: reason,
    });
    setSurveyVisible(false);
    setIsModalOpen(false);
  };

  const closeAbonnementenModal = () => {
    if (userCredits === 0) {
      setSurveyVisible(true);
    } else {
      setSurveyVisible(false);
      setIsModalOpen(false);
    }
  };

  const handleChooseKindbundel = async () => {
    if (!isAuthenticated || !user) {
      alert('U moet ingelogd zijn om een bundel te kiezen.');
      return;
    }
    try {
      const response = await axios.post('/initiate_payment', {
        type: 'premium_child',
        email: user.email,
      });
      window.location.href = response.data.payment_url;
    } catch (error) {
      console.error('Error initiating payment:', error);
      alert('Er is een fout opgetreden bij het starten van de betaling.');
    }
  };

  const plans = [
    {
      key: 'child',
      title: 'Kindbundel',
      image: '/40_books.webp',
      oldPrice: '12,99',
      newPrice: '9,99',
      features: [
        '40 verhalen',
        'Afbeeldingen',
        'Uitbreiden & Delen',
        'Niet tevreden? Binnen 7 dagen je geld terug',
      ],
      button: (
        <Button variant="contained" sx={{ mt: 1 }} onClick={handleChooseKindbundel}>
          Betaal hier
        </Button>
      ),
    },
    {
      key: 'org',
      title: 'Organisatie',
      image: '/500_books.webp',
      price: 'Prijs op maat',
      shortFeatures: [
        'Meerdere accounts',
        'Afbeeldingen & Video’s',
        'Single Sign On (optioneel)',
      ],
      extraFeatures: [
        'Automatisch accepteren leerlingen',
        'Uitbreiden & Delen',
        'Verhaal check vragen',
        'Niet tevreden? Binnen 7 dagen geld terug',
      ],
      button: (
        <Button
          variant="outlined"
          color="secondary"
          fullWidth
          href="mailto:info@verhalen-maker.nl"
          sx={{ mt: 1 }}
        >
          Neem contact op
        </Button>
      ),
    },
  ];

  const paymentMethods = [
    { src: '/ideal_icon.png', alt: 'iDEAL' },
    { src: '/paypal_logo.png', alt: 'PayPal' },
    { src: '/visa_logo.webp', alt: 'Visa' },
    { src: '/bancontact_logo.png', alt: 'Bancontact' },
    { src: '/mastercard_logo.png', alt: 'Mastercard' },
  ];

  return (
    <AbonnementenModalContext.Provider
      value={{ isModalOpen, openAbonnementenModal, closeAbonnementenModal }}
    >
      {children}

      <Dialog
        open={isModalOpen}
        onClose={closeAbonnementenModal}
        maxWidth="md"
        fullWidth
        disableScrollLock
        PaperProps={{
          sx: {
            p: 2,
            borderRadius: 2,
            position: 'relative',
            overflow: 'hidden',
            background: `linear-gradient(
              135deg,
              ${theme.palette.background.paper} 25%,
              ${theme.palette.grey[100]} 50%,
              ${theme.palette.background.paper} 75%
            )`,
            backgroundSize: '400% 400%',
            animation: 'glance 15s ease infinite',
          },
        }}
        TransitionProps={{ appear: false }}
      >
        {!surveyVisible && (
          <IconButton
            aria-label="close"
            onClick={closeAbonnementenModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[600],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        <DialogTitle sx={{ textAlign: 'center', pb: 1 }}>
          <Typography variant="h6" component="div" sx={{ fontWeight: 'medium' }}>
            Koop Extra Verhalen
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ pt: 1 }}>
          {surveyVisible && userCredits === 0 ? (
            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Typography variant="body2" sx={{ mb: 2, textAlign: 'left' }}>
                We willen graag begrijpen waarom je geen bundel hebt gekocht zodat wij onze producten aan kunnen aanpassen.
              </Typography>
              {['Anders', 'Niet interessant', 'Te weinig functionaliteiten', 'Kwaliteit', 'Andere oplossingen', 'Te duur'].map(reason => (
                <Button
                  key={reason}
                  variant="contained"
                  color="primary"
                  onClick={() => closeModalWithReason(reason)}
                  sx={{ m: 1 }}
                >
                  {reason}
                </Button>
              ))}
            </Box>
          ) : (
            <>
              {isAuthenticated && userCredits !== null && (
                <Typography
                  variant="body2"
                  align="left"
                  sx={{ mb: 2, color: 'text.secondary' }}
                >
                  Hallo <strong>{userName}</strong>, je hebt momenteel{' '}
                  <strong>{userCredits}</strong> credits over. <br />
                  {/* {daysUntilRefill !== null && (
                    <>
                      De volgende gratis aanvulling is over{' '}
                      <strong>
                        {daysUntilRefill} dag{daysUntilRefill !== 1 ? 'en' : ''}
                      </strong>.
                    </>
                  )} */}
                </Typography>
              )}

              <Grid container spacing={2}>
                {plans.map((plan) => {
                  const isOrg = plan.key === 'org';
                  const featuresToShow = isOrg
                    ? plan.shortFeatures
                    : plan.features;

                  return (
                    <Grid item xs={12} sm={6} key={plan.key}>
                      <Card
                        variant="outlined"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          p: 2,
                          height: '100%',
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={plan.image}
                          alt={plan.title}
                          sx={{ height: 120, borderRadius: 1, objectFit: 'cover' }}
                        />
                        <CardContent sx={{ flexGrow: 1, px: 0 }}>
                          <Typography
                            variant="subtitle1"
                            align="center"
                            sx={{ mt: 2, fontWeight: 'bold' }}
                          >
                            {plan.title}
                          </Typography>

                          {isOrg ? (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              align="center"
                              sx={{ mb: 2 }}
                            >
                              {plan.price}
                            </Typography>
                          ) : (
                            <Box textAlign="center" sx={{ mb: 2 }}>
                              <Typography
                                variant="body2"
                                sx={{
                                  textDecoration: 'line-through',
                                  color: 'text.secondary',
                                  mr: 1,
                                  fontSize: '0.85rem',
                                }}
                              >
                                €{plan.oldPrice}
                              </Typography>
                              <Typography
                                variant="h5"
                                component="span"
                                sx={{ color: 'primary', fontWeight: 'bold' }}
                              >
                                €{plan.newPrice}
                              </Typography>
                              <Chip
                                label="KORTING"
                                color="secondary"
                                size="small"
                                sx={{ ml: 1 }}
                              />
                            </Box>
                          )}

                          <Box
                            component="ul"
                            sx={{
                              listStyle: 'none',
                              p: 0,
                              m: 0,
                              fontSize: '0.9rem',
                              color: 'text.secondary',
                            }}
                          >
                            {featuresToShow.map((feat) => (
                              <li key={feat} style={{ marginBottom: 4 }}>
                                • {feat}
                              </li>
                            ))}
                            {isOrg && (
                              <Collapse in={orgExpanded}>
                                {plan.extraFeatures?.map((feat) => (
                                  <li key={feat} style={{ marginBottom: 4 }}>
                                    • {feat}
                                  </li>
                                ))}
                              </Collapse>
                            )}
                          </Box>

                          {isOrg && plan.extraFeatures && plan.extraFeatures.length > 0 && (
                            <Button
                              variant="text"
                              size="small"
                              onClick={() => setOrgExpanded((prev) => !prev)}
                              sx={{ mt: 1 }}
                            >
                              {orgExpanded ? 'Minder details' : 'Meer details'}
                            </Button>
                          )}
                        </CardContent>
                        {plan.button}
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>

              <Box
                sx={{
                  mt: 4,
                  textAlign: 'center',
                  position: 'relative',
                  height: 100,
                }}
              >
                <Slide
                  direction="right"
                  in={true}
                  key={reviews[currentReviewIndex].name}
                  timeout={{ enter: 700, exit: 1000 }}
                >
                  <Box sx={{ position: 'absolute', width: '100%' }}>
                    <Slide direction="right" in={true} timeout={{ enter: 700, exit: 1000 }}>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ fontStyle: 'italic', color: 'text.secondary' }}
                        >
                          “{reviews[currentReviewIndex].text}”
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          sx={{ mt: 0.5, color: 'text.secondary' }}
                        >
                          — {reviews[currentReviewIndex].name}
                        </Typography>
                      </Box>
                    </Slide>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mt: 1,
                        gap: 0.5,
                      }}
                    >
                      {[...Array(5)].map((_, i) => (
                        <Slide
                          key={i}
                          direction="right"
                          in={true}
                          style={{ transitionDelay: `${i * 300}ms` }}
                          timeout={{ enter: 700, exit: 1000 }}
                          mountOnEnter
                          unmountOnExit
                        >
                          <StarIcon sx={{ color: 'gold' }} />
                        </Slide>
                      ))}
                    </Box>
                  </Box>
                </Slide>
              </Box>

              <Box sx={{ textAlign: 'center', mt: 6 }}>
                <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
                  Betaalmethodes
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 2,
                    flexWrap: 'wrap',
                  }}
                >
                  {paymentMethods.map((method) => (
                    <Box
                      key={method.alt}
                      sx={{
                        width: 36,
                        height: 36,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <img src={method.src} alt={method.alt} width={36} height={36} />
                    </Box>
                  ))}
                </Box>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </AbonnementenModalContext.Provider>
  );
};

export default AbonnementenModalProvider;
