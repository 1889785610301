import React from 'react';
import { Typography, Box, Container, Link as MuiLink } from '@mui/material';

const PrivacybeleidPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Privacybeleid Verhalen-maker.nl
      </Typography>

      <Box sx={{ mt: 4, textAlign: 'left' }}>
        <Typography variant="body1" paragraph>
          Verhalen-maker.nl, gevestigd aan Weidegangweg 30, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Contactgegevens:
        </Typography>
        <Typography variant="body1" paragraph>
          Website: <MuiLink href="https://www.verhalen-maker.nl/" target="_blank" rel="noopener noreferrer">https://www.verhalen-maker.nl/</MuiLink><br />
          Adres: Weidegangweg 30<br />
          Telefoonnummer: +31 6 8369 1679<br />
          Functionaris Gegevensbescherming: Lisanne Hendriks<br />
          E-mailadres: <MuiLink href="mailto:info@verhalen-maker.nl">info@verhalen-maker.nl</MuiLink>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Persoonsgegevens die wij verwerken
        </Typography>
        <Typography variant="body1" paragraph>
          Verhalen-maker.nl verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt. Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:
        </Typography>
        <Typography variant="body1" component="ul">
          <li>Voor- en achternaam</li>
          <li>Adresgegevens</li>
          <li>Telefoonnummer</li>
          <li>E-mailadres</li>
          <li>IP-adres</li>
          <li>Bankrekeningnummer</li>
          <li>Gegevens over jouw activiteiten op onze website</li>
          <li>Internetbrowser en apparaattype</li>
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>Bijzondere en/of gevoelige persoonsgegevens:</strong><br />
          Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar, tenzij zij toestemming hebben van ouders of voogd. Wij raden ouders aan betrokken te zijn bij de online activiteiten van hun kinderen om te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je vermoedt dat wij zonder toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via <MuiLink href="mailto:info@verhalen-maker.nl">info@verhalen-maker.nl</MuiLink>, zodat wij deze informatie kunnen verwijderen.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
        </Typography>
        <Typography variant="body1" paragraph>
          Verhalen-maker.nl verwerkt jouw persoonsgegevens voor de volgende doelen:
        </Typography>
        <Typography variant="body1" component="ul">
          <li>Het afhandelen van jouw betaling</li>
          <li>Verzenden van onze nieuwsbrief en/of reclame</li>
          <li>Je te kunnen bellen of e-mailen indien nodig om onze dienstverlening uit te voeren</li>
          <li>Je te informeren over wijzigingen van onze diensten en producten</li>
          <li>Je de mogelijkheid te bieden een account aan te maken</li>
          <li>
            Verhalen-maker.nl analyseert jouw gedrag op de website om daarmee de website te verbeteren en het aanbod van producten en diensten af te stemmen op jouw voorkeuren
          </li>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Geautomatiseerde besluitvorming
        </Typography>
        <Typography variant="body1" paragraph>
          Verhalen-maker.nl neemt geen besluiten op basis van geautomatiseerde verwerkingen die (aanzienlijke) gevolgen kunnen hebben voor personen.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Hoe lang we persoonsgegevens bewaren
        </Typography>
        <Typography variant="body1" paragraph>
          Verhalen-maker.nl bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Specifieke bewaartermijnen zijn beschikbaar op aanvraag via <MuiLink href="mailto:info@verhalen-maker.nl">info@verhalen-maker.nl</MuiLink>.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Delen van persoonsgegevens met derden
        </Typography>
        <Typography variant="body1" paragraph>
          Verhalen-maker.nl deelt jouw persoonsgegevens alleen met derden indien dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze opdracht, sluiten wij een verwerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Verhalen-maker.nl blijft verantwoordelijk voor deze verwerkingen.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Cookies of vergelijkbare technieken die wij gebruiken
        </Typography>
        <Typography variant="body1" paragraph>
          Verhalen-maker.nl gebruikt functionele, analytische en tracking cookies. Deze cookies zijn nodig voor de technische werking van de website en jouw gebruiksgemak. Ze onthouden bijvoorbeeld jouw voorkeursinstellingen en helpen ons de website te optimaliseren. Bij je eerste bezoek aan onze website informeren wij je over deze cookies en vragen wij je toestemming voor het plaatsen ervan.
        </Typography>
        <Typography variant="body1" paragraph>
          Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle eerder opgeslagen informatie via de instellingen van je browser verwijderen. Voor meer informatie over cookies, zie: <MuiLink href="https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/" target="_blank" rel="noopener noreferrer">veiliginternetten.nl</MuiLink>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Gegevens inzien, aanpassen of verwijderen
        </Typography>
        <Typography variant="body1" paragraph>
          Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je toestemming voor gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Verhalen-maker.nl. Ook heb je recht op gegevensoverdraagbaarheid.
        </Typography>
        <Typography variant="body1" paragraph>
          Je kunt een verzoek indienen via <MuiLink href="mailto:info@verhalen-maker.nl">info@verhalen-maker.nl</MuiLink>. Om er zeker van te zijn dat het verzoek door jou is gedaan, vragen wij een kopie van je identiteitsbewijs mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone), paspoortnummer en BSN zwart ter bescherming van je privacy. Wij reageren zo snel mogelijk, uiterlijk binnen vier weken, op jouw verzoek.
        </Typography>
        <Typography variant="body1" paragraph>
          Daarnaast kun je een klacht indienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens, via de volgende link: 
          <MuiLink href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons" target="_blank" rel="noopener noreferrer">
            https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
          </MuiLink>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Hoe wij persoonsgegevens beveiligen
        </Typography>
        <Typography variant="body1" paragraph>
          Verhalen-maker.nl neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijzigingen tegen te gaan. Als je vermoedt dat jouw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op via <MuiLink href="mailto:info@verhalen-maker.nl">info@verhalen-maker.nl</MuiLink>
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Maatregelen:</strong><br />
          - TLS (voorheen SSL): wij versturen jouw gegevens via een beveiligde internetverbinding. Dit kun je zien aan de adresbalk 'https' en het hangslotje in de adresbalk.<br />
          - Andere specifieke beveiligingsmaatregelen zijn beschikbaar op aanvraag.
        </Typography>

        <Typography variant="body2" color="textSecondary" align="right" sx={{ mt: 4 }}>
          Laatst bijgewerkt: 12-1-2025
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacybeleidPage;