
// src/pages/OverOnsPage.js
import React from 'react';
import { Link as MuiLink, Typography, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const OverOnsPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Over Ons
      </Typography>

      <Typography variant="h5" gutterBottom>
        Het begon met een idee
      </Typography>
      <Typography variant="body1" paragraph>
        Het begon allemaal met een simpel idee, geboren uit mijn persoonlijke ervaring als moeder van twee kinderen.
        Mijn missie is duidelijk: kinderen motiveren om te lezen. Als moeder weet ik hoe uitdagend het kan zijn
        om een kind enthousiast te maken voor lezen. Mijn jongste vond lezen helemaal niets. Elke avond probeerde
        ik hem te boeien met spannende verhalen, maar niets leek echt zijn aandacht te grijpen.
      </Typography>
      <Typography variant="body1" paragraph>
        Totdat ik het anders aanpakte. Ik begon een website met verhalen vol avontuur en thema's zoals
        Star Wars en Minecraft – precies wat hij leuk vindt. Hij kon zelfs de hoofdpersonen zelf kiezen.
        Tot mijn verrassing werkte het! Voor het eerst begon hij zelf om meer verhalen te vragen.
        Lezen werd iets waar hij naar uitkeek in plaats van een strijd.
      </Typography>
      <Typography variant="body1" paragraph>
        Dit persoonlijke succes inspireerde me om Verhalen-maker.nl te starten. Want als mijn zoon zoveel plezier
        uit verhalen kan halen, waarom zouden andere kinderen dat ook niet kunnen? Met mijn website maak ik
        verhalen toegankelijk en leuk voor iedereen. Ik geloof dat lezen deuren opent naar een wereld vol
        kansen en dromen.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Overweldigende reacties
      </Typography>
      <Typography variant="body1" paragraph>
        Wat begon als een klein idee, is inmiddels uitgegroeid tot iets veel groters. De onwijs positieve reacties
        van verschillende bedrijven en personen hebben me geraakt – ik ben er zelfs een beetje stil van geworden,
        en dat is in mijn geval heel uitzonderlijk! Het geeft me enorm veel energie om door te gaan en nog meer
        kinderen te bereiken.
      </Typography>
      <Typography variant="body1" paragraph>
        Het maakt me trots om te zien dat niet alleen basisscholen enthousiast zijn over wat we doen, maar dat
        we inmiddels ook samenwerken met middelbare scholen. Bovendien werken we hard aan een uitbreiding van
        de website, zodat nog meer kinderen en ouders plezier kunnen beleven aan onze verhalen.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Mijn missie: lezen leuk maken
      </Typography>
      <Typography variant="body1" paragraph>
        Als moeder begrijp ik hoe belangrijk het is om kinderen op jonge leeftijd enthousiast te maken voor lezen.
        Dat is niet altijd eenvoudig, maar met de juiste tools en aanpak is het wél mogelijk. Met Verhalen-maker.nl
        bied ik verhalen die kinderen aanspreken, waarin ze zichzelf kunnen herkennen of zelfs een rol kunnen spelen.
        Lezen wordt daardoor een avontuur, geen verplichting.
      </Typography>
      <Typography variant="body1" paragraph>
        Op de website zijn verschillende bundels beschikbaar, zodat er voor ieder wat wils is. Dit helpt ons niet
        alleen om kinderen te verrassen en te inspireren, maar ook om de website voortdurend te vernieuwen en
        aan te passen aan hun wensen.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Samen lezen stimuleren
      </Typography>
      <Typography variant="body1" paragraph>
        We werken graag samen met scholen, logopedisten en taalkundigen om lezen op een leuke en toegankelijke
        manier te stimuleren. Mocht je interesse hebben in meer informatie? Mail dan naar
        <strong> info@verhalen-maker.nl</strong>. We denken graag met je mee over hoe we dit op maat kunnen maken.
      </Typography>

      <Box display="flex" justifyContent="center" mt={4}>
        <Box
          component="img"
          src="/profileimage.png"
          alt="Foto van [Naam]"
          sx={{
            width: { xs: '150px', sm: '200px' },
            height: 'auto',
            borderRadius: '50%',
            boxShadow: 3,
            objectFit: 'cover',
          }}
        />
      </Box>

      <Box sx={{ mt: 6, textAlign: 'center', borderTop: '1px solid #ccc', pt: 2 }}>
        <Typography variant="body2">
          <MuiLink component={Link} to="/algemene-voorwaarden" underline="hover" sx={{ mx: 1 }}>
            Algemene Voorwaarden
          </MuiLink>
          |
          <MuiLink component={Link} to="/privacybeleid" underline="hover" sx={{ mx: 1 }}>
            Privacybeleid
          </MuiLink>
        </Typography>
        <Typography variant="caption" color="textSecondary" display="block" sx={{ mt: 1 }}>
          © {new Date().getFullYear()} Verhalen-maker.nl
        </Typography>
      </Box>
    </Container>
  );
};

export default OverOnsPage;