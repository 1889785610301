// src/pages/AlgemeneVoorwaardenPage.js

import React from 'react';
import { Typography, Box, Container } from '@mui/material';

const AlgemeneVoorwaardenPage = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Algemene Voorwaarden Verhalen-maker.nl
      </Typography>

      <Box sx={{ mt: 4, textAlign: 'left' }}>
        <Typography variant="h6" gutterBottom>
          Artikel 1 - Definities
        </Typography>
        <Typography variant="body1" paragraph>
          In deze algemene voorwaarden wordt verstaan onder:
        </Typography>
        <Typography variant="body1" component="ul">
          <li>
            <strong>Verhalen-maker.nl:</strong> de handelsnaam van de onderneming, gevestigd aan Weidegangweg 30, 
            die diensten aanbiedt via de website <a href="https://www.verhalen-maker.nl/" target="_blank" rel="noopener noreferrer">https://www.verhalen-maker.nl/</a>.
          </li>
          <li>
            <strong>Gebruiker:</strong> iedere natuurlijke of rechtspersoon die gebruik maakt van de diensten en/of producten van Verhalen-maker.nl.
          </li>
          <li>
            <strong>Overeenkomst:</strong> de overeenkomst tussen Verhalen-maker.nl en de gebruiker met betrekking tot het leveren van diensten of producten.
          </li>
          <li>
            <strong>Diensten:</strong> alle door Verhalen-maker.nl aangeboden producten en diensten, waaronder maar niet beperkt tot het schrijven, publiceren en aanbieden van verhalen en gerelateerde content.
          </li>
        </Typography>

        <Typography variant="h6" gutterBottom>
          Artikel 2 - Toepasselijkheid
        </Typography>
        <Typography variant="body1" paragraph>
          1. Deze algemene voorwaarden zijn van toepassing op alle aanbiedingen, overeenkomsten en leveringen van Verhalen-maker.nl, tenzij schriftelijk anders is overeengekomen.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Door gebruik te maken van de diensten van Verhalen-maker.nl, stemt de gebruiker in met deze algemene voorwaarden.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Eventuele afwijkingen van deze algemene voorwaarden zijn alleen geldig indien deze schriftelijk door Verhalen-maker.nl zijn bevestigd.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Artikel 3 - Aanbod en Overeenkomst
        </Typography>
        <Typography variant="body1" paragraph>
          1. Alle aanbiedingen van Verhalen-maker.nl zijn vrijblijvend en onder voorbehoud, tenzij uitdrukkelijk anders vermeld.
        </Typography>
        <Typography variant="body1" paragraph>
          2. De overeenkomst komt tot stand op het moment dat de gebruiker een dienst of product afneemt via de website en hiervan een bevestiging ontvangt.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Verhalen-maker.nl behoudt zich het recht voor een bestelling of aanvraag te weigeren zonder opgaaf van redenen.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Artikel 4 - Prijzen en Betaling
        </Typography>
        <Typography variant="body1" paragraph>
          1. Alle vermelde prijzen zijn inclusief btw, tenzij anders aangegeven.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Betaling dient te geschieden via de op de website aangegeven betaalmethoden.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Indien betaling niet tijdig plaatsvindt, behoudt Verhalen-maker.nl zich het recht voor de dienstverlening op te schorten of te beëindigen.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Artikel 5 - Levering en Uitvoering
        </Typography>
        <Typography variant="body1" paragraph>
          1. Verhalen-maker.nl spant zich in om de diensten naar beste inzicht en vermogen uit te voeren.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Levertermijnen zijn indicatief en vormen geen fatale termijnen. Overschrijding van een termijn geeft de gebruiker geen recht op schadevergoeding of ontbinding van de overeenkomst.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Verhalen-maker.nl behoudt zich het recht voor om diensten te wijzigen of te beëindigen indien noodzakelijk.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Artikel 6 - Herroepingsrecht
        </Typography>
        <Typography variant="body1" paragraph>
          1. Gebruikers hebben het recht om binnen 14 dagen na aankoop de overeenkomst zonder opgave van redenen te herroepen, mits de dienst nog niet volledig is uitgevoerd.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Om gebruik te maken van het herroepingsrecht dient de gebruiker contact op te nemen via <a href="mailto:lisanne@verhalen-maker.nl">lisanne@verhalen-maker.nl</a>.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Indien de dienst al gedeeltelijk is uitgevoerd, behoudt Verhalen-maker.nl zich het recht voor om een evenredige vergoeding in rekening te brengen.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Artikel 7 - Aansprakelijkheid
        </Typography>
        <Typography variant="body1" paragraph>
          1. Verhalen-maker.nl is uitsluitend aansprakelijk voor directe schade die het gevolg is van opzet of grove schuld.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Verhalen-maker.nl is niet aansprakelijk voor indirecte schade, waaronder maar niet beperkt tot gevolgschade, winstderving of verlies van gegevens.
        </Typography>
        <Typography variant="body1" paragraph>
          3. De aansprakelijkheid van Verhalen-maker.nl is te allen tijde beperkt tot het bedrag dat de gebruiker heeft betaald voor de betreffende dienst.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Artikel 8 - Intellectueel Eigendom
        </Typography>
        <Typography variant="body1" paragraph>
          1. Alle door Verhalen-maker.nl verstrekte content, waaronder verhalen, teksten en afbeeldingen, blijven eigendom van Verhalen-maker.nl, tenzij uitdrukkelijk anders overeengekomen.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Het is gebruikers niet toegestaan om zonder schriftelijke toestemming van Verhalen-maker.nl verstrekte content te kopiëren, verspreiden of openbaar te maken.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Artikel 9 - Klachtenregeling
        </Typography>
        <Typography variant="body1" paragraph>
          1. Klachten over de uitvoering van de overeenkomst dienen binnen 14 dagen na ontdekking schriftelijk te worden gemeld via <a href="mailto:lisanne@verhalen-maker.nl">lisanne@verhalen-maker.nl</a>.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Verhalen-maker.nl streeft ernaar om binnen 14 dagen na ontvangst van de klacht een oplossing te bieden.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Indien de klacht niet naar tevredenheid wordt opgelost, kan de gebruiker zich wenden tot de bevoegde rechter.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Artikel 10 - Toepasselijk Recht en Geschillen
        </Typography>
        <Typography variant="body1" paragraph>
          1. Op alle overeenkomsten en geschillen met betrekking tot Verhalen-maker.nl is uitsluitend Nederlands recht van toepassing.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Geschillen worden voorgelegd aan de bevoegde rechter in het arrondissement waar Verhalen-maker.nl is gevestigd.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Artikel 11 - Wijziging van de Algemene Voorwaarden
        </Typography>
        <Typography variant="body1" paragraph>
          1. Verhalen-maker.nl behoudt zich het recht voor om deze algemene voorwaarden te wijzigen of aan te vullen.
        </Typography>
        <Typography variant="body1" paragraph>
          2. Wijzigingen worden ten minste 30 dagen voor de ingangsdatum bekendgemaakt via de website.
        </Typography>
        <Typography variant="body1" paragraph>
          3. Indien de gebruiker niet akkoord gaat met de wijzigingen, heeft deze het recht om de overeenkomst te beëindigen voordat de wijzigingen van kracht worden.
        </Typography>

        <Typography variant="body2" color="textSecondary" align="right" sx={{ mt: 4 }}>
          Laatst bijgewerkt: 12-1-2025
        </Typography>
      </Box>
    </Container>
  );
};

export default AlgemeneVoorwaardenPage;