import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Typography,
  Box,
  Fade,
  CircularProgress,
  LinearProgress,
  Container,
  useTheme,
  useMediaQuery,
  IconButton,
  ButtonGroup,
} from '@mui/material';
import { keyframes } from '@mui/system';
import ReactMarkdown from 'react-markdown';

import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { UserStatusContext } from '../../contexts/UserStatusContext';
import { fetch_story_by_uuid, enhance_story } from '../../services/StoryService';
import { showLoginModal } from '../LoginPromptModal';
import { useAbonnementenModal } from '../AbonnementenModal';
import { addCreateStoryTimestamp } from '../../utils/rateLimiter';
import ReCAPTCHAComponent from '../../pages/homepage_components/ReCAPTCHAComponent';

import ImageSection from './ImageSection';
import EmailModal from './EmailModal';
import SpeedDialActions from './SpeedDialActions';
import SnackbarAlert from './SnackbarAlert';
import EnhanceButton from './EnhanceButton';
import ImageLoader from './ImageLoader'; // Import the ImageLoader component

import jsPDF from 'jspdf';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import EnhanceIcon from '@mui/icons-material/Build';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

// ----------------- ANIMATION KEYFRAMES -----------------
const pulsate = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(103, 58, 183, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(103, 58, 183, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(103, 58, 183, 0);
  }
`;

// ----------------- UTILS -----------------
/** Utility to strip basic Markdown for TTS usage */
function stripMarkdown(md) {
  return md
    .replace(/\*\*(.*?)\*\*/g, '$1') // Bold **text**
    .replace(/__(.*?)__/g, '$1')     // Bold __text__
    .replace(/`([^`]+)`/g, '$1')     // Inline code `text`
    .replace(/\*\*/g, '')            // Remove remaining **
    .replace(/__/g, '')              // Remove remaining __
    .replace(/#+/g, '')              // Remove # and ##
    .replace(/\*/g, '')              // Remove remaining *
    .replace(/_/g, '');              // Remove remaining _
}

/** Splits text on punctuation to form an array of sentences */
function splitIntoSentences(text) {
  const regex = /[^.!?]+[.!?]+[\])'"`’”]*|.+/g;
  return text.match(regex) || [];
}

// ----------------- TTS PLAYER -----------------
/**
 * Manages TTS states & playback. We also remove `[IMAGE_n]` references
 * so the TTS won’t read them.
 */
function TTSPlayer({ storyText }) {

  const cleanedText = stripMarkdown(storyText).replace(/\[IMAGE[^\]]*\]/gi, '');
  const sentences = splitIntoSentences(cleanedText);

  const [audioBlobs, setAudioBlobs] = useState(Array(sentences.length).fill(null));
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [status, setStatus] = useState('');
  const audioPlayerRef = useRef(null);

  // Fetch TTS audio for a single sentence
  const fetchSentenceAudio = async (index) => {
    if (audioBlobs[index]) return audioBlobs[index]; // Already fetched
    if (!sentences[index]) return null;

    try {
      const response = await fetch('/synthesize', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: sentences[index].trim() }),
      });
      if (!response.ok) {
        return null;
      }
      const blob = await response.blob();
      setAudioBlobs((prev) => {
        const newBlobs = [...prev];
        newBlobs[index] = blob;
        return newBlobs;
      });
      return blob;
    } catch (error) {
      return null;
    }
  };

  // Play a sentence by index
  const playSentence = async (index) => {
    if (!audioPlayerRef.current) return;
    const blob = await fetchSentenceAudio(index);
    if (!blob) return;

    const audioUrl = URL.createObjectURL(blob);
    audioPlayerRef.current.src = audioUrl;

    audioPlayerRef.current.play().then(() => {
      setIsPlaying(true);
      setIsPaused(false);
      setCurrentSentenceIndex(index);

      // Prefetch next sentence
      if (index + 1 < sentences.length) {
        fetchSentenceAudio(index + 1);
      }
    }).catch(() => {
      setStatus('Fout bij afspelen audio.');
    });
  };

  // Handle when one sentence finishes playing
  const handleAudioEnded = () => {
    if (audioPlayerRef.current && audioPlayerRef.current.src) {
      URL.revokeObjectURL(audioPlayerRef.current.src);
      audioPlayerRef.current.src = '';
    }
    const nextIndex = currentSentenceIndex + 1;
    if (nextIndex < sentences.length) {
      playSentence(nextIndex);
    } else {
      setIsPlaying(false);
      setStatus('Verhaal afgespeeld.');
      resetPlayer();
    }
  };

  const resetPlayer = () => {
    setIsPlaying(false);
    setIsPaused(false);
    setCurrentSentenceIndex(0);
    setStatus('');
  };

  const startPlaying = async () => {
    if (isPlaying || !sentences.length) return;
    await playSentence(0);
  };

  const stopPlaying = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.pause();
      audioPlayerRef.current.currentTime = 0;
      audioPlayerRef.current.src = '';
    }
    resetPlayer();
  };

  const pausePlaying = () => {
    if (!audioPlayerRef.current || !isPlaying) return;
    audioPlayerRef.current.pause();
    setIsPaused(true);
    setStatus('Gepauzeerd.');
  };

  const resumePlaying = () => {
    if (!audioPlayerRef.current || !isPlaying) return;
    audioPlayerRef.current.play().then(() => {
      setIsPaused(false);
      setStatus(`Zin ${currentSentenceIndex + 1} hervat...`);
    }).catch(() => {
      setStatus('Fout bij hervatten audio.');
    });
  };

  if (!storyText || !sentences.length) return null;

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <ButtonGroup sx={{ mb: 1 }}>
        <IconButton
          color="primary"
          onClick={isPlaying ? stopPlaying : startPlaying}
          disabled={!sentences.length}
          aria-label={isPlaying ? 'Stop verhaal afspelen' : 'Start verhaal afspelen'}
        >
          {isPlaying ? <StopIcon /> : <PlayArrowIcon />}
        </IconButton>

        <IconButton
          color="primary"
          disabled={!isPlaying}
          onClick={isPaused ? resumePlaying : pausePlaying}
          aria-label={isPaused ? 'Hervat verhaal' : 'Pauzeer verhaal'}
        >
          {isPaused ? <PlayCircleIcon /> : <PauseIcon />}
        </IconButton>
      </ButtonGroup>

      {status && (
        <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
          {status}
        </Typography>
      )}

      <audio ref={audioPlayerRef} onEnded={handleAudioEnded} style={{ display: 'none' }} />
    </Box>
  );
}

// ----------------- MEMOIZED MARKDOWN RENDERER -----------------
const StoryContent = React.memo(function StoryContent({ markdown, uuid }) {
  const components = {
    p: ({ node, children, ...props }) => {
      const imageTagRegex = /^\[IMAGE_(\d+)\]$/;
      const childText = children[0];

      if (typeof childText === 'string') {
        const match = childText.trim().match(imageTagRegex);
        if (match) {
          const imageNumber = parseInt(match[1], 10);
          return (
            <ImageLoader
              key={`image-${imageNumber}`}
              uuid={uuid}
              imageNumber={imageNumber}
            />
          );
        }
      }

      return (
        <Typography component="p" {...props} sx={{ mb: 2 }}>
          {children}
        </Typography>
      );
    },
  };

  return (
    <Box sx={{ width: '100%', mb: 3 }}>
      <ReactMarkdown components={components}>{markdown}</ReactMarkdown>
    </Box>
  );
});

// ----------------- MAIN STORYDISPLAY -----------------
function StoryDisplay() {
  const { uuid } = useParams();

  // Basic story states
  const [originalMarkdown, setOriginalMarkdown] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [base64Image, setBase64Image] = useState(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [loadingStory, setLoadingStory] = useState(true);
  const [storyError, setStoryError] = useState(null);
  const [isPremiumStory, setIsPremiumStory] = useState(false);

  // Generate PDF states
  const [isGenerating, setIsGenerating] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  // Email Modal
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSending, setEmailSending] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(null);
  const [emailErrorState, setEmailErrorState] = useState(null);

  // Fade in
  const [fadeIn, setFadeIn] = useState(false);

  // Enhance story
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [enhanceError, setEnhanceError] = useState(null);

  // reCAPTCHA
  const recaptchaRef = useRef(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const WEBSITE_DOMAIN = process.env.REACT_APP_WEBSITE_DOMAIN;
  const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;

  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const { userCredits } = useContext(UserStatusContext);
  const { openAbonnementenModal, AbonnementenModal } = useAbonnementenModal();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // ----------------- FETCH STORY -----------------
  useEffect(() => {
    setFadeIn(false);

    const fetchStory = async () => {
      try {
        const { email, story, image_url, ispremium } = await fetch_story_by_uuid(uuid);

        const storyText = story || '';
        setOriginalMarkdown(storyText);
        // Strip SAS query from the returned image URL
        setImageUrl((image_url || '').split('?')[0]);
        setEmail(email || '');
        setIsPremiumStory(ispremium || false);
        setLoadingStory(false);

        // Google Analytics
        ReactGA.event({
          category: 'StoryDisplay',
          action: 'created_story_displayed',
          label: 'Story Display Page Loaded',
        });
      } catch (error) {
        setStoryError(error.message);
        setLoadingStory(false);
      }
    };

    fetchStory();
  }, [uuid]);

  useEffect(() => {
    if (!loadingStory && !storyError) {
      setFadeIn(true);
    }
  }, [loadingStory, storyError]);

  // ----------------- FETCH IMAGE BASE64 -----------------
  useEffect(() => {
    const fetchBase64Image = async () => {
      if (!imageUrl) return;
      setLoadingImage(true);
      setImageError(false);

      try {
        const response = await fetch(`/api/getImage?url=${encodeURIComponent(imageUrl)}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to fetch image.');
        }
        const data = await response.json();
        setBase64Image(data.base64);
      } catch (error) {
        setImageError(true);
      } finally {
        setLoadingImage(false);
      }
    };
    fetchBase64Image();
  }, [imageUrl]);

  // ----------------- DOWNLOAD / SHARE / EMAIL -----------------
  const handleDownload = async () => {
    setIsGenerating(true);
    setDownloadError(null);
    try {
      const doc = new jsPDF('p', 'pt', 'a4');
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margin = 40;
      let yPosition = margin;

      // Header
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(20);
      doc.text('Verhaal van Verhalen-maker.nl', pageWidth / 2, yPosition, {
        align: 'center',
      });
      yPosition += 30;

      // Image
      if (base64Image) {
        try {
          const imgProps = doc.getImageProperties(base64Image);
          const imgWidth = pageWidth - 2 * margin;
          const imgHeight = (imgProps.height * imgWidth) / imgProps.width;
          doc.addImage(base64Image, 'PNG', margin, yPosition, imgWidth, imgHeight);
          yPosition += imgHeight + 20;
        } catch (error) {
          // If image fails, skip it
        }
      }

      // Title
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(16);
      doc.text('Jouw Verhaal', pageWidth / 2, yPosition, { align: 'center' });
      yPosition += 25;

      // Story Text
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(12);
      const plainText = stripMarkdown(originalMarkdown);
      const textLines = doc.splitTextToSize(plainText, pageWidth - 2 * margin);

      const addText = (lines) => {
        let currentY = yPosition;
        lines.forEach((line) => {
          if (currentY > pageHeight - margin) {
            doc.addPage();
            currentY = margin;
          }
          doc.text(line, margin, currentY);
          currentY += 14;
        });
      };
      addText(textLines);

      // Page Numbers
      const totalPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i);
        doc.setFontSize(10);
        doc.text(`Pagina ${i} van ${totalPages}`, pageWidth / 2, pageHeight - 10, {
          align: 'center',
        });
      }

      doc.save('verhaal.pdf');
    } catch (error) {
      setDownloadError(
        'Er is een fout opgetreden bij het genereren van de PDF. Probeer het opnieuw.'
      );
    } finally {
      setIsGenerating(false);
    }
  };

  const handleShare = () => {
    const shareUrl = `https://${WEBSITE_DOMAIN}/story/${uuid}`;
    const text = encodeURIComponent(`Bekijk dit verhaal: ${shareUrl}`);
    window.open(`https://wa.me/?text=${text}`, '_blank');

    ReactGA.event({
      category: 'User Interaction',
      action: 'share_whatsapp_clicked',
      label: 'User shared story via WhatsApp',
    });
  };

  const handleOpenEmailModal = () => {
    setEmail('');
    setEmailSuccess(null);
    setEmailErrorState(null);
    setEmailModalOpen(true);

    ReactGA.event({
      category: 'User Interaction',
      action: 'send_email_clicked',
      label: 'User clicked send email button',
    });
  };

  const handleCloseEmailModal = () => setEmailModalOpen(false);

  const handleSendEmail = async () => {
    setEmailSending(true);
    setEmailSuccess(null);
    setEmailErrorState(null);

    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error('Ongeldig e-mailadres.');
      }

      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, uuid }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Fout bij het verzenden van de e-mail.');
      }

      const data = await response.json();
      setEmailSuccess(data.message || 'E-mail succesvol verzonden!');
    } catch (error) {
      setEmailErrorState(error.message);
    } finally {
      setEmailSending(false);
    }
  };

  // ----------------- ENHANCE STORY -----------------
  const handleEnhanceStory = () => {
    if (!isAuthenticated) {
      showSnackbar('Je moet ingelogd zijn om het verhaal uit te breiden.', 'info');
      showLoginModal();
      return;
    }
    if (email && user.email !== email) {
      showSnackbar('Alleen de gebruiker die dit verhaal heeft gemaakt, kan het verbeteren.', 'error');
      return;
    }
    if (userCredits === 0) {
      showSnackbar('Je hebt geen credits meer om het verhaal uit te breiden.', 'info');
      openAbonnementenModal();
      return;
    }
    if (recaptchaRef.current) {
      recaptchaRef.current.execute();
    }
  };

  const onReCAPTCHAChange = async (token) => {
    if (!token) {
      setEnhanceError('CAPTCHA is niet voltooid.');
      return;
    }
    setIsEnhancing(true);
    setEnhanceError(null);

    try {
      let accessToken = '';
      if (isAuthenticated) {
        try {
          accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: AUTH0_AUDIENCE,
              scope: 'read:current_user',
            },
          });
        } catch (tokenError) {
          showSnackbar('Authenticatie is mislukt. Probeer het opnieuw.', 'error');
          setIsEnhancing(false);
          return;
        }
      }

      addCreateStoryTimestamp();
      await enhance_story(
        { email, uuid, captchaToken: token, isPremium: isPremiumStory },
        accessToken
      );
      window.location.reload();
    } catch (error) {
      setEnhanceError(error.message);
      showSnackbar(error.message || 'Er is een onverwachte fout opgetreden.', 'error');
    } finally {
      setIsEnhancing(false);
    }
  };

  // ----------------- SNACKBAR -----------------
  const showSnackbar = (message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  // ----------------- SPEED DIAL ACTIONS -----------------
  const speedDialActions = [
    {
      icon: <WhatsAppIcon />,
      name: 'Deel via WhatsApp',
      action: handleShare,
      tooltip: 'Deel via WhatsApp',
    },
    {
      icon: isGenerating ? <CircularProgress size={24} /> : <DownloadIcon />,
      name: 'Download Verhaal',
      action: handleDownload,
      tooltip: 'Download Verhaal',
      disabled: isGenerating,
    },
    {
      icon: <EmailIcon />,
      name: 'Verstuur verhaal',
      action: handleOpenEmailModal,
      tooltip: 'Verstuur verhaal via E-mail',
    },
    {
      icon: isEnhancing ? <CircularProgress size={24} /> : <EnhanceIcon />,
      name: 'Volgende hoofdstuk',
      action: handleEnhanceStory,
      tooltip: 'Volgende hoofdstuk',
      disabled: isEnhancing,
    },
  ];

  // ----------------- RENDER -----------------
  return (
    <Fade in={fadeIn} timeout={500}>
      <Container
        maxWidth="md"
        sx={{
          mt: 6,
          mb: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingX: { xs: 2, sm: 4 },
        }}
      >
        {loadingStory ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : storyError ? (
          <Typography color="error" align="center" sx={{ my: 4 }}>
            {storyError}
          </Typography>
        ) : (
          <>
            {/* TITLE */}
            <Typography variant="h4" align="center" gutterBottom>
              Jouw Verhaal
            </Typography>

            {/* TOP IMAGE SECTION */}
            <ImageSection
              loadingImage={loadingImage}
              imageError={imageError}
              base64Image={base64Image}
              imageUrl={imageUrl}
              isPremiumStory={isPremiumStory}
            />

            {/* TTS PLAYER (no console logging & skipping [IMAGE_n]) */}
            <TTSPlayer storyText={originalMarkdown} />

            {/* MEMOIZED MARKDOWN RENDER */}
            <StoryContent markdown={originalMarkdown} uuid={uuid} />

            {/* ENHANCE BUTTON & PROGRESS */}
            <EnhanceButton
              isEnhancing={isEnhancing}
              handleEnhanceStory={handleEnhanceStory}
              pulsate={pulsate}
            />
            {isEnhancing && (
              <Box sx={{ width: '100%', my: 2 }}>
                <LinearProgress />
                <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                  Volgende hoofdstuk wordt geschreven...
                </Typography>
              </Box>
            )}
          </>
        )}

        {/* SPEED DIAL */}
        <SpeedDialActions actions={speedDialActions} fabLabel="Deel!" theme={theme} />

        {/* EMAIL MODAL */}
        <EmailModal
          email={email}
          setEmail={setEmail}
          open={emailModalOpen}
          handleClose={handleCloseEmailModal}
          handleSendEmail={handleSendEmail}
          emailSending={emailSending}
          emailSuccess={emailSuccess}
          emailError={emailErrorState}
        />

        {/* SNACKBAR */}
        <SnackbarAlert
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          handleClose={handleCloseSnackbar}
        />

        {/* RECAPTCHA FOR ENHANCE */}
        <Box sx={{ mt: 4 }}>
          <ReCAPTCHAComponent recaptchaRef={recaptchaRef} onReCAPTCHAChange={onReCAPTCHAChange} />
        </Box>

        {/* ABONNEMENTEN MODAL */}
        {AbonnementenModal}
      </Container>
    </Fade>
  );
}

export default StoryDisplay;
