// src/components/StoryDisplay/ImageSection.js

import React from 'react';
import { Box, CircularProgress, Typography, Skeleton, Alert } from '@mui/material';

const ImageSection = ({ loadingImage, imageError, base64Image, imageUrl, isPremiumStory }) => {
  return (
    <Box
      sx={{
        my: 4,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {loadingImage ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : imageError ? (
        <Typography color="error" align="center" sx={{ my: 4 }}>
          Er is een probleem opgetreden bij het laden van de afbeelding.
        </Typography>
      ) : base64Image ? (
        <Box
          component="img"
          src={base64Image}
          alt="Story Image"
          sx={{
            width: '100%',
            height: 'auto',
            borderRadius: 2,
          }}
        />
      ) : !imageUrl && !isPremiumStory ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ borderRadius: 2 }}
        />
      ) : null}
      {!imageUrl && !isPremiumStory && (
        <Alert severity="info" sx={{ width: '100%', mt: 2 }}>
          Wil je bij je volgende verhaal ook plaatjes zien? Log dan even in! Lukt het niet? Vraag dan hulp aan papa, mama, je juf of meester! (let op, na inloggen moet je een nieuw verhaal maken om dit te zien)
        </Alert>
      )}
    </Box>
  );
};

export default ImageSection;
