import React, { useState, useEffect, useRef } from 'react';
import { Box, Skeleton, Fade, Typography, Alert } from '@mui/material';
import PropTypes from 'prop-types';

function ImageLoader({ uuid, imageNumber }) {
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const pollingInterval = 5000;
  const maxAttempts = 50;
  const intervalRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    let attempt = 0;

    const fetchImage = async () => {
      try {
        const response = await fetch(
          `/api/getImageByNumber?uuid=${encodeURIComponent(uuid)}&number=${imageNumber}`
        );
        if (response.ok) {
          const data = await response.json();
          if (data.imageUrl && isMounted) {
            // Strip SAS query from URL
            setImageUrl(data.imageUrl.split('?')[0]);
            setIsLoading(false);
            clearInterval(intervalRef.current);
          }
        } else if (response.status !== 404) {
          console.error(`Error fetching image ${imageNumber}:`, response.statusText);
        }
      } catch (error) {
        console.error(`Error fetching image ${imageNumber}:`, error);
      }
    };

    fetchImage();
    intervalRef.current = setInterval(() => {
      if (attempt >= maxAttempts) {
        clearInterval(intervalRef.current);
        setIsLoading(false);
        return;
      }
      attempt += 1;
      fetchImage();
    }, pollingInterval);

    return () => {
      isMounted = false;
      clearInterval(intervalRef.current);
    };
  }, [uuid, imageNumber]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        my: 4
      }}
    >
      {imageUrl ? (
        <Fade in timeout={500}>
          <img
            src={imageUrl}
            alt={`Image ${imageNumber}`}
            style={{ maxWidth: '100%', height: 'auto', borderRadius: 8 }}
          />
        </Fade>
      ) : isLoading ? (
        <>
          <Alert severity="info" sx={{ mb: 2, width: 300 }}>
            Afbeelding wordt gemaakt!
          </Alert>
          <Skeleton variant="rectangular" width={300} height={200} />
        </>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="body2" color="textSecondary">
            Afbeelding niet beschikbaar
          </Typography>
        </Box>
      )}
    </Box>
  );
}

ImageLoader.propTypes = {
  uuid: PropTypes.string.isRequired,
  imageNumber: PropTypes.number.isRequired,
};

export default ImageLoader;
