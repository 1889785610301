// src/components/StoryDisplay/SpeedDialActions.js

import React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

const NoTooltip = ({ children }) => children;

const SpeedDialActions = ({ actions, fabLabel, theme }) => {
  return (
    <SpeedDial
      ariaLabel="Verhaal Acties"
      sx={{
        position: 'fixed',
        bottom: theme.spacing(12),
        right: theme.spacing(2),
        '& .MuiSpeedDial-fab': {
          width: '120px',
          height: '48px',
          borderRadius: '8px',
          padding: theme.spacing(0.5, 2),
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
      icon={
        <Typography variant="button" sx={{ fontWeight: 'bold', color: 'white' }}>
          {fabLabel}
        </Typography>
      }
      direction="up"
      FabProps={{
        color: 'primary',
      }}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={
            <Typography variant="caption" noWrap>
              {action.tooltip}
            </Typography>
          }
          onClick={action.action}
          tooltipOpen
          FabProps={{
            disabled: action.disabled,
          }}
          TooltipComponent={NoTooltip}
        />
      ))}
    </SpeedDial>
  );
};

export default SpeedDialActions;
