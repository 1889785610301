// src/components/StoryDisplay/EnhanceButton.js

import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import EnhanceIcon from '@mui/icons-material/Build';

const EnhanceButton = ({ isEnhancing, handleEnhanceStory, pulsate }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
      <Button
        variant="contained"
        onClick={handleEnhanceStory}
        disabled={isEnhancing}
        sx={{
          backgroundColor: 'primary.main',
          color: 'white',
          fontWeight: 600,
          textTransform: 'none',
          animation: `${pulsate} 2s infinite`,
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
        startIcon={isEnhancing ? <CircularProgress size={20} color="inherit" /> : <EnhanceIcon />}
      >
        {isEnhancing ? 'Schrijven...' : 'Volgende hoofdstuk!'}
      </Button>
    </Box>
  );
};

export default EnhanceButton;
